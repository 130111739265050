import React, {useState} from "react";
import Grid from "@mui/material/Grid";
import {
    ArrayInput,
    DateInput,
    Edit,
    FormDataConsumer,
    required,
    SaveButton,
    SelectInput,
    SimpleForm,
    SimpleFormIterator,
    Toolbar,
    useDataProvider,
    useEditContext, useNotify
} from "react-admin";
import {COMMUNICATION_TYPE} from "../dicts/CallReportEnums";
import EnumDictInput from "../controls/EnumDictInput";
import Resources from "../../Resources";

import {useQuery} from "@tanstack/react-query";
import RichTextEditor, {RICH_TEXT_ENTITIES} from "../controls/RichTextEditor";
import LegalEntityInput from "../controls/LegalEntityInput";
import PersonArrayInput from "../controls/PersonArrayInput";
import {PRODUCT_APPLICATION_TYPES} from "../dicts/PartyEnums";
import DictInput from "../controls/DictInput";
import {onError} from "../../common/utils";
import {useWatch} from "react-hook-form";

function transformData(formData) {
    if(formData?.productApplications) {
        formData.productApplications.forEach((app) => {
            if(app.applicationTypedKey) {
                const parsedApp = app.applicationTypedKey.split(":");
                app.applicationId = parsedApp[0];
                app.applicationType = parsedApp[1];
            }
        })
    }
    if(formData?.monivoltRepsParticipantIds) {
        if(!formData.monivoltReps) {
            formData.monivoltReps = [];
        }

        let i = formData.monivoltReps.length
        while (i--) {
            if (!formData.monivoltRepsParticipantIds.includes(formData.monivoltReps[i].participantId)) {
                formData.monivoltReps.splice(i, 1);
            }
        }
        formData.monivoltRepsParticipantIds.forEach((participantId) => {
            const participantExists = formData.monivoltReps.some((rep) => rep.participantId === participantId);
            if(!participantExists) {
                formData.monivoltReps.push({participantId: participantId})
            }
        })
    }
    return formData;
}

function TemplateSelector(props) {
    const {onChange} = props
    const contentOfTheCommunicationEdit = useWatch({name: 'content'})

    return <DictInput label="Template" source="templateCode"
                      reference={Resources.DICT_CALL_REPORT_TEMPLATES.name}
                      fullWidth
                      onChange={onChange}
                      disabled={!!contentOfTheCommunicationEdit}
    />
}

function CallReportEditForm(props) {

    const dataProvider = useDataProvider();
    const [content, setContent] = useState("");
    const notify = useNotify();

    const { record, isLoading } = useEditContext();

    let contentJoditEditor = null;

    function setContentJoditEditorRef(editor) {
        contentJoditEditor = editor;
    }
    if(record) {
        if(record.productApplications?.length > 0) {
            record.productApplications.forEach((app) => app.applicationTypedKey = app.applicationId + ":" + app.applicationType)
        }
        if(record.monivoltReps?.length > 0) {
            record.monivoltRepsParticipantIds = record.monivoltReps.map((participant) => (participant.participantId));
        }
    }

    function validateCallReport(values) {
        const errors = {};
        if (!values.communicationDate) {
            errors.communicationDate = 'Communication Date is required';
        }
        if(!values.communicationType) {
            errors.communicationType = 'Communication Type is required';
        }
        if(!values.monivoltRepsParticipantIds || values.monivoltRepsParticipantIds.length === 0) {
            errors.monivoltRepsParticipantIds = 'Monivolt Reps are required';
        }
        if(!values.contactPersons || values.contactPersons.length === 0) {
            errors.contactPersons = 'Contact Persons are required';
        } else {
            // validate for empty fields
            const undefinedParticipants = values.contactPersons.some((contact) => !contact || !contact.participantEmployerId || !contact.participantId);
            if(undefinedParticipants) {
                errors.contactPersons = 'Undefined contact person.';
            } else {
                // validate for duplicates
                const ids = values.contactPersons.map((contact) => contact.participantId);
                const duplicatesFound = ids.some((element, index) => {
                    return ids.indexOf(element) !== index
                });
                if (duplicatesFound) {
                    errors.contactPersons = 'One or more contact persons have been added multiple times. It is forbidden.';
                }
            }
        }
        if(!values.content) {
            errors.content = 'Content is required';
        }
        return errors
    };

    function PostCreateToolbar() {
        return (
            <Toolbar>
                <SaveButton
                    type="button"
                    transform={transformData}
                />

            </Toolbar>
        );
    }

    function onTemplateChange(v) {
        let code = v?.target?.value;
        if (contentJoditEditor && code) {
            dataProvider.getOne(Resources.DICT_CALL_REPORT_TEMPLATES.name, {id: code})
                .then(({data}) => {
                    contentJoditEditor.value = data.description;
                })
                .catch((error) => {
                    onError(error, notify);
                });
        }
    }

    return (
        <SimpleForm validate={validateCallReport}>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <DateInput source="communicationDate" label="Communication Date" validate={required()} fullWidth/>
                </Grid>
                <Grid item xs={2}>
                    <EnumDictInput enum={COMMUNICATION_TYPE} source="communicationType" label="Communication Type" required fullWidth/>
                </Grid>
                <Grid item xs={3}>
                </Grid>
                <Grid item xs={7}>
                    <PersonArrayInput source="monivoltRepsParticipantIds" additionalFilter={{monivoltRep: true}} required label="Monivolt Rep" />
                </Grid>
                <Grid item xs={12}>
                    <ArrayInput source="contactPersons" fullWidth label="Contact Persons" >
                        <SimpleFormIterator fullWidth sx={12} inline disableReordering>

                            <LegalEntityInput source="participantEmployerId" label="Organisation" isRequired sx={{width:"35%"}}/>

                            <FormDataConsumer>
                                {({formData, scopedFormData}) => {
                                    const { data, isInitialLoading, error } = useQuery({
                                        queryKey: ['organisationContacts', scopedFormData?.participantEmployerId],
                                        queryFn: () => {
                                            return dataProvider['doAction'](Resources.LEGAL_ENTITY.name, {
                                                action: Resources.LEGAL_ENTITY.subrequests.PERSONS.name,
                                                id: scopedFormData?.participantEmployerId,
                                                method: 'GET'
                                            });
                                        },
                                        enabled: !!scopedFormData?.participantEmployerId
                                    });
                                    const contacts = data?.data;
                                    return (
                                        <>
                                            <SelectInput label="Contact Person" validate={required()}
                                                         source="participantId" /*disabled={!contacts}*/
                                                         choices={contacts ? contacts : []}
                                                         optionText={(record) => record.id + ": " + record.firstName + " " + record.lastName}
                                                         sx={{width:"30%"}}
                                            />
                                        </>
                                    )}}
                            </FormDataConsumer>
                        </SimpleFormIterator>
                    </ArrayInput>
                </Grid>
                <Grid item xs={12}>
                    <ArrayInput source="productApplications" fullWidth label="Product Applications" >
                        <SimpleFormIterator fullWidth sx={12} inline disableReordering>
                            <LegalEntityInput source="partyId" label="Organisation" isRequired sx={{width:"35%"}}/>
                            <FormDataConsumer>
                                {({formData, scopedFormData}) => {
                                    const { data, isInitialLoading, error } = useQuery({
                                        queryKey: ['productApplications', scopedFormData?.partyId],
                                        queryFn: () => {
                                            return dataProvider['doAction'](Resources.LEGAL_ENTITY.name, {
                                                action: Resources.LEGAL_ENTITY.subrequests.APPLICATIONS.name,
                                                id: scopedFormData?.partyId,
                                                method: 'GET'
                                            });
                                        },
                                        enabled: !!scopedFormData?.partyId
                                    });
                                    const applications = data?.data.map((app) => ({...app, typedKey: `${app.id}:${app.applicationType}`}));

                                    return (
                                        <>
                                            <SelectInput label="Product Application" validate={required()}
                                                         source="applicationTypedKey" disabled={!applications}
                                                         optionValue="typedKey"
                                                         choices={applications ? applications : []}
                                                         optionText={(record) => `${PRODUCT_APPLICATION_TYPES[record.applicationType].label} ${record.name}`}
                                                         sx={{width:"30%"}}
                                            />
                                        </>
                                    )}}
                            </FormDataConsumer>
                        </SimpleFormIterator>
                    </ArrayInput>
                </Grid>
                <Grid item xs={4}>
                    <TemplateSelector onChange={onTemplateChange}/>
                </Grid>
                <Grid item xs={12}>
                    <RichTextEditor setJoditEditorRef={setContentJoditEditorRef} entityType={RICH_TEXT_ENTITIES.CALL_REPORT} entityId={record.id}
                                           placeholder="Content of the communication" label="Content of the communication"
                                           source="content"
                />
                </Grid>
                <Grid item xs={12}>
                    <DateInput source="nextStepsDate" label="Next Steps Date" />
                </Grid>
                <Grid item xs={12}>
                    <RichTextEditor entityType={RICH_TEXT_ENTITIES.CALL_REPORT} entityId={record.id}
                                    placeholder="Next Steps" label="Next Steps" source="nextSteps" />
                </Grid>
            </Grid>
        </SimpleForm>
    );
}

function redirectBack() {
    history.back();
    return false;
}

function CallReportEditPage(props) {

    return (
        <Edit redirect={redirectBack} transform={transformData} >
            <CallReportEditForm />
        </Edit>
    );
}

export default CallReportEditPage;