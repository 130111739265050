import {Admin, AppBar, ToggleThemeButton, Layout, Resource, defaultTheme } from 'react-admin';
import DictIcon from '@mui/icons-material/Book';
import {
    LoanApplicationCreate,
    LoanApplicationEdit,
    LoanApplicationList,
    LoanApplicationShow
} from './components/view/LoanApplications';
import {Dashboard} from './components/dashboard';
import MvtDataProvider from "./components/data/MvtDataProvider";
import AuthProvider from './components/auth/CognitoAuthProvider';
import React from "react";
import Resources from "./components/Resources";
import {
    CallReportTemplateCreate,
    CallReportTemplateEdit, CallReportTemplateList,
    DictCreate,
    DictEdit,
    DictList,
    ExtApisList
} from "./components/view/dicts/Dict";
import {CurrentSpotCreate, CurrentSpotEdit, CurrentSpotList, HistorySpotList} from "./components/view/CurrentSpot";
import {SettingEdit, SettingList} from "./components/view/Settings";
import {CurrencyDictCreate, CurrencyDictEdit, CurrencyDictList} from "./components/view/dicts/CurrencyDict";
import {ReportCreate, ReportList} from "./components/view/Reports";
import {
    CreditorApplicationCreate,
    CreditorApplicationList,
    CreditorApplicationShow
} from "./components/view/CreditorApplications";
import CallReportListPage from "./components/view/callReports/CallReportListPage";
import CallReportEditPage from "./components/view/callReports/CallReportEditPage";
import CallReportShowPage from "./components/view/callReports/CallReportShowPage";
import LegalEntitiesListPage from "./components/view/legalEntities/LegalEntitiesListPage";
import LegalEntitiesEditPage from "./components/view/legalEntities/LegalEntitiesEditPage";
import LegalEntitiesCreatePage from "./components/view/legalEntities/LegalEntitiesCreatePage";
import LegalEntitiesShowPage from "./components/view/legalEntities/LegalEntitiesShowPage";
import PartPartyRelCreatePage from "./components/view/partyPartyRels/PartPartyRelCreatePage";
import PartPartyRelEditPage from "./components/view/partyPartyRels/PartPartyRelEditPage";
import PartyPartyRelListPage from "./components/view/partyPartyRels/PartyPartyRelListPage";
import PersonsListPage from "./components/view/persons/PersonsListPage";
import PersonShowPage from "./components/view/persons/PersonsShowPage";
import PersonsCreatePage from "./components/view/persons/PersonsCreatePage";
import PersonsEditPage from "./components/view/persons/PersonsEditPage";
import AuditLogList from "./components/view/audit/AuditLogList";

export default function Home() {
    const dataProvider = MvtDataProvider(AuthProvider);
    const lightTheme = defaultTheme;
    const darkTheme = { ...defaultTheme, palette: { mode: 'dark' } };
    return (
        <div suppressHydrationWarning>
            {typeof window === 'undefined' ? null : (
                <Admin authProvider={AuthProvider} dataProvider={dataProvider}
                       lightTheme={lightTheme}
                       darkTheme={darkTheme}
                       dashboard={Dashboard} disableTelemetry>
                    {/*<Resource options={{label: "Lead requests"}} name={Resources.LEAD_REQUESTS.name} list={LeadRequestList}/>*/}
                    <Resource options={{label: "Loan applications"}} name={Resources.LOAN_APPLICATIONS.name} list={LoanApplicationList}
                              edit={LoanApplicationEdit}  show={LoanApplicationShow} create={LoanApplicationCreate}/>
                    <Resource options={{label: "Creditor applications"}} name={Resources.CREDITOR_APPLICATIONS.name} list={CreditorApplicationList}
                              /*edit={CreditorApplicationEdit}*/  show={CreditorApplicationShow} create={CreditorApplicationCreate}/>
                    <Resource options={{label: Resources.LEGAL_ENTITY.multiLabel}} name={Resources.LEGAL_ENTITY.name} list={LegalEntitiesListPage}
                              recordRepresentation={(rec) => rec.id + ": " + rec.fullCompanyName}
                              edit={LegalEntitiesEditPage} show={LegalEntitiesShowPage} create={LegalEntitiesCreatePage}/>
                    <Resource options={{label: Resources.PERSONS.multiLabel}} name={Resources.PERSONS.name}
                              recordRepresentation={(rec) => `${rec.id}: ${rec.firstName} ${rec.lastName}`}
                              list={PersonsListPage} edit={PersonsEditPage} show={PersonShowPage} create={PersonsCreatePage}/>
                    <Resource options={{label: Resources.PARTY_PARTY_REL.multiLabel}} name={Resources.PARTY_PARTY_REL.name}
                              list={PartyPartyRelListPage} create={PartPartyRelCreatePage} edit={PartPartyRelEditPage}/>
                    <Resource options={{label: Resources.CALL_REPORT.multiLabel}} name={Resources.CALL_REPORT.name} list={CallReportListPage}
                              edit={CallReportEditPage} show={CallReportShowPage}/>
                    <Resource name={Resources.AUDIT.name} list={AuditLogList} />
                    {/*<Resource options={{label: "Loans"}} name={Resources.LOANS.name} edit={LoanEdit} list={LoanList} show={LoanShow}/>*/}
                    {/*<Resource options={{label: Resources.ACCOUNTS.multiLabel}} name={Resources.ACCOUNTS.name} list={AccountList} show={AccountShow} create={AccountCreate}/>*/}
                    {/*<Resource options={{label: Resources.DEPOSIT_NOTIFICATIONS.multiLabel}} name={Resources.DEPOSIT_NOTIFICATIONS.name}*/}
                    {/*          list={DepositNotificationList} show={DepositNotificationShow} create={DepositNotificationCreate}/>*/}
                    {/*<Resource options={{label: Resources.OPERATIONS.multiLabel}} name={Resources.OPERATIONS.name} list={OperationList} create={OperationCreate} edit={OperationEdit} show={OperationShow}/>*/}
                    {/*<Resource options={{label: Resources.COINBASE_TRANSACTIONS.multiLabel}} name={Resources.COINBASE_TRANSACTIONS.name} list={CoinbaseTransactionList}  />*/}
                    <Resource name={Resources.DICT_CALL_REPORT_TEMPLATES.name}
                              options={{label: "Dict - Meeting Notes Templates"}}
                              list={CallReportTemplateList}
                              edit={CallReportTemplateEdit}
                              create={CallReportTemplateCreate}
                              icon={DictIcon}
                    />
                    <Resource name={Resources.DICT_LOAN_TYPES.name} options={{label: "Dict - Loan Types"}} list={DictList} edit={DictEdit} create={DictCreate} icon={DictIcon}/>
                    <Resource name={Resources.DICT_DOC_TYPES.name} options={{label: "Dict - Doc Types"}} list={DictList} edit={DictEdit} create={DictCreate} icon={DictIcon}/>
                    {/*<Resource name={Resources.DICT_LEGAL_FORMS.name} list={DictList} edit={DictEdit} create={DictCreate} icon={DictIcon}/>*/}
                    <Resource name={Resources.DICT_CURRENCIES.name} options={{label: "Dict - Currencies"}} list={CurrencyDictList} edit={CurrencyDictEdit} create={CurrencyDictCreate}
                              icon={DictIcon} />
                    {/*<Resource name={Resources.DICT_REQUEST_TYPES.name} list={DictList} edit={DictEdit} create={DictCreate} icon={DictIcon}/>*/}
                    {/*<Resource name={Resources.DICT_LOAN_STATUSES.name} list={DictList} edit={DictEdit} create={DictCreate} icon={DictIcon}/>*/}
                    {/*<Resource name={Resources.DICT_ASSET_CLASSES.name} options={{label: "Dict - Asset Classes"}} list={DictList} edit={DictEdit} create={DictCreate} icon={DictIcon}/>*/}
                    <Resource name={Resources.DICT_BORROWER_TYPES.name} options={{label: "Dict - Borrower Types"}} list={DictList} edit={DictEdit} create={DictCreate} icon={DictIcon}/>
                    <Resource name={Resources.DICT_SECTORS.name} options={{label: "Dict - Sector"}} list={DictList} edit={DictEdit} create={DictCreate} icon={DictIcon}/>
                    {/*<Resource name={Resources.DICT_ACCOUNT_PURPOSE_TYPE.name} list={AccountPurposeDictList} edit={AccountPurposeDictEdit} create={AccountPurposeDictCreate} icon={DictIcon}/>*/}
{/*
                    <Resource options={{label: Resources.DICT_ACCOUNT_DETAILS.multiLabel}}
                              name={Resources.DICT_ACCOUNT_DETAILS.name}
                              list={AccountDetailsDictList} edit={AccountDetailsDictEdit} create={AccountDetailsDictCreate} icon={DictIcon}/>
*/}
                    <Resource name={Resources.EXT_API.name} options={{label: "Dict - Ext APIs"}} list={ExtApisList} icon={DictIcon}/>
                    <Resource name={Resources.CURRENT_SPOTS.name} list={CurrentSpotList} icon={DictIcon}
                              create={CurrentSpotCreate} edit={CurrentSpotEdit}/>
                    <Resource name={Resources.HISTORY_SPOTS.name} list={HistorySpotList} icon={DictIcon}/>
                    <Resource name={Resources.SETTINGS.name} list={SettingList} edit={SettingEdit}/>
                    <Resource options={{label: Resources.REPORTS.multiLabel}} name={Resources.REPORTS.name}
                              list={ReportList} create={ReportCreate}/>
                </Admin>)}
        </div>
    )
}
