import React, {useState} from "react";
import DialogTitle from "@mui/material/DialogTitle";
import {
    Button,
    Form,
    TextInput,
    SimpleFormIterator, ArrayInput, useNotify, useRefresh, useDataProvider
} from "react-admin";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Resources from "../../Resources";
import {onError, onSuccess} from "../../common/utils";

function CreateTermSheetDialog(props) {
    const {onClose, isOpen, loanApplication} = props;
    const [submitting, setSubmitting] = useState(false);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();

    function onSubmit(formData) {
        setSubmitting(true);
        dataProvider['doBodyAction'](Resources.LOAN_APPLICATIONS.name, {
            action: Resources.LOAN_APPLICATIONS.subrequests.TERM_SHEET.name,
            id: loanApplication.id,
            method: "POST",
            body: formData,
        }).then(({data}) => {
            if (data.success) {
                onSuccess(onClose, setSubmitting, refresh);
            } else {
                setSubmitting(false);
                const msg =
                    data.errMessage
                        ? data.errMessage
                        : "System error";
                onError({message: msg}, notify)
            }
        }).catch(error => {
            onError(error, notify);
            setSubmitting(false);
        });
    }

    return (
        <Dialog onClose={onClose} open={isOpen} fullWidth maxWidth="sm">
            <Form onSubmit={onSubmit}>
                <DialogTitle>Create a Term Sheet</DialogTitle>
                <DialogContent>
                    <ArrayInput source="values" fullWidth label="Term sheet values">
                        <SimpleFormIterator fullWidth sx={12} inline disableReordering>
                            <TextInput source="key" fullWidth/>
                            <TextInput source="value" fullWidth/>
                        </SimpleFormIterator>
                    </ArrayInput>

                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} label="Cancel"/>
                    <Button type="submit" disabled={submitting} label="Submit"/>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

export default CreateTermSheetDialog;