import React, {useState} from "react";
import DialogTitle from "@mui/material/DialogTitle";
import {
    Button,
    Form,
    TextInput,
    useNotify, useRefresh, useDataProvider
} from "react-admin";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Grid from "@mui/material/Grid";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Resources from "../../Resources";
import {onError, onSuccess} from "../../common/utils";
import DeleteIcon from '@mui/icons-material/Delete';

function UpdateTermSheetDialog(props) {
    const {onClose, isOpen, loanApplication, offerId, offerParamId, offerParamKey, offerParamValue} = props;
    const [submitting, setSubmitting] = useState(false);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();

    const formRecord = {offerKey: offerParamKey, offerValue: offerParamValue};


    function onSubmit(formData) {

        const postAction = `${Resources.LOAN_APPLICATIONS.subrequests.TERM_SHEET.name}/${offerId}/values`;
        const putAction = `${postAction}/${offerParamId}`;
        setSubmitting(true);
        dataProvider['doBodyAction'](Resources.LOAN_APPLICATIONS.name, {
            action: offerParamId ? putAction : postAction,
            id: loanApplication.id,
            method: offerParamId ? "PUT" : "POST",
            body: formData,
        }).then(({data}) => {
            if (data.success) {
                onSuccess(onClose, setSubmitting, refresh);
            } else {
                setSubmitting(false);
                const msg =
                    data.errMessage
                        ? data.errMessage
                        : "System error";
                onError({message: msg}, notify)
            }
        }).catch(error => {
            onError(error, notify);
            setSubmitting(false);
        });
    }

    function deleteValue() {
        dataProvider['doAction'](Resources.LOAN_APPLICATIONS.name, {
            action: `${Resources.LOAN_APPLICATIONS.subrequests.TERM_SHEET.name}/${offerId}/values/${offerParamId}`,
            id: loanApplication.id,
            method: "DELETE",
        }).then(({data}) => {
            if (data.success) {
                onSuccess(onClose, setSubmitting, refresh);
            } else {
                setSubmitting(false);
                const msg =
                    data.errMessage
                        ? data.errMessage
                        : "System error";
                onError({message: msg}, notify)
            }
        }).catch(error => {
            onError(error, notify);
            setSubmitting(false);
        });

    }

    return (
        <Dialog onClose={onClose} open={isOpen} fullWidth maxWidth="sm">
            <Form onSubmit={onSubmit} record={formRecord}>
                <DialogTitle>
                    Update a Term Sheet:
                    {offerParamKey && <> {offerParamKey}</>}
                    {!offerParamKey && <> Create a new value</>}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {offerParamKey && <>Update {offerParamKey}</>}
                        {!offerParamKey && <>Create a new value</>}
                    </DialogContentText>
                    <TextInput isRequired label="Name" source="offerKey"/>
                    <TextInput isRequired label="Value" source="offerValue"/>

                </DialogContent>
                <DialogActions>
                    <Grid container width="100%" alignContent="space-between">
                        <Grid item xs={8}>
                            <Button onClick={deleteValue} disabled={submitting} label="Delete"
                                    startIcon={<DeleteIcon color="error"/>}/>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container justifyContent="end">
                                <Grid item>
                                    <Button onClick={onClose} label="Cancel"/>
                                </Grid>
                                <Grid item>
                                    <Button type="submit" disabled={submitting} label="Submit"/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

export default UpdateTermSheetDialog;