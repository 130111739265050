import {
    AutocompleteInput,
    Create,
    Datagrid,
    DateField, DeleteWithConfirmButton,
    Edit,
    EditButton,
    FormDataConsumer,
    List,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SaveButton,
    SimpleForm,
    TextField,
    Toolbar,
    TopToolbar, useGetList, useGetOne,
} from "react-admin";
import Resources from "../Resources";
import DateTimeFieldLondon from "./controls/DateFieldTZ";
import * as React from "react";
import Grid from "@mui/material/Grid";
import {AmountInput} from "./controls/AmountInput";
import {CheckPermission} from "./security/CheckPermission";
import {AdminPortalRoles} from "./dicts/Security";
import {FunctionField} from "ra-ui-materialui";
import {CurrencyInput} from "./controls/CurrencyInput";
import {useFormContext, useWatch} from "react-hook-form";
import {Typography} from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {DictLoader} from "./controls/DictLoader";
import {DICTS} from "../common/constants";
import {useEffect, useState} from "react";

const EditToolbar = props => {
    return <Toolbar {...props}>
        <CheckPermission role={AdminPortalRoles.BACKOFFICE_APPROVER}>
            <SaveButton/>
            <DeleteWithConfirmButton
                confirmContent="You will not be able to recover this record. Are you sure?"
            />
        </CheckPermission>
    </Toolbar>
};

const SpotEditableForm = () => {
    const currencyFrom = useWatch({name: 'currencyFrom'});
    const currencyTo = useWatch({name: 'currencyTo'});
    const {setValue} = useFormContext();
    const [currencyMap, setCurrencyMap] = useState({});
    const [currencyToDisabled, setCurrencyToDisabled] = useState(false);

    useGetList(Resources.DICT_CURRENCIES.name, {sort: {field: 'code', order: 'ASC'}}, {
        onSuccess: (data) => {
            console.log("data", data)
            setCurrencyMap(data.data.reduce((a, b) => ({...a, [b.code]: b}), {}));

            if (currencyFrom) {
                currencyFromOnChange(currencyFrom)
            }
        }
    });

    const currencyFromOnChange = (value) => {
        const c = currencyMap[value];
        console.log(c)
        if (c?.tradeCurrencyCode) {
            setValue('currencyTo', c.tradeCurrencyCode);
            setCurrencyToDisabled(true);
        } else {
            setCurrencyToDisabled(false);
        }
    }
    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} className="info">
                    <InfoOutlinedIcon/>
                    <Typography fontSize="13px" >
                        It's possible to create or edit rates only for manually managed currencies
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <CurrencyInput source="currencyFrom"
                                   reference={Resources.DICT_MANUAL_CURRENCIES.name}
                                   fullWidth
                                   required
                                   onChange={currencyFromOnChange}
                    />
                </Grid>
                <Grid item xs={4}>
                    <CurrencyInput source="currencyTo"
                                   reference={Resources.DICT_LOAN_CURRENCIES.name}
                                   fullWidth
                                   required
                                   disabled={currencyToDisabled}
                                   excludes={[currencyFrom]}
                    />
                </Grid>
                <Grid item xs={2}>
                    <AmountInput source="amount" required fullWidth currency={currencyTo}/>
                </Grid>
                <Grid item xs={2}>
                    <ReferenceInput source="sourceApiCode" label="Source API" reference={Resources.EXT_API.name}
                                    sort={{field: 'code', order: 'ASC'}}>
                        <AutocompleteInput optionText="name" optionValue="code" defaultValue="MANUAL" disabled={true}/>
                    </ReferenceInput>
                </Grid>
            </Grid>
        </>
    );
}

export const CurrentSpotEdit = (props) => {
    return (
        <Edit {...props} redirect="list">
            <SimpleForm toolbar={<EditToolbar/>}>
                <FormDataConsumer>
                    {({formData, ...rest}) => (
                        <SpotEditableForm rest/>
                    )}
                </FormDataConsumer>
            </SimpleForm>
        </Edit>
    );
}

export const CurrentSpotCreate = (props) => {
    return (
        <Create {...props} redirect="list">
            <SimpleForm>
                <FormDataConsumer>
                    {({formData, ...rest}) => (
                        <SpotEditableForm rest/>
                    )}
                </FormDataConsumer>
            </SimpleForm>
        </Create>
    );
}

export const CurrentSpotList = (props) => (
    <List {...props} sort={{field: "currencyFrom", order: "ASC"}} perPage={25}>
        <Datagrid rowClick={false} bulkActionButtons={false}>
            <TextField source="currencyFrom"/>
            <TextField source="currencyTo"/>
            <NumberField source="amount"/>
            <DateField source="receivedDate" label="Received Date (Local)" showTime/>
            <DateTimeFieldLondon source="receivedDate" label={"Received Date (London)"}/>
            <ReferenceField label="Source API" source="sourceApiCode" reference={Resources.EXT_API.name}>
                <TextField source="name"/>
            </ReferenceField>
            <CheckPermission role={AdminPortalRoles.BACKOFFICE_APPROVER}>
                <FunctionField render={(record) => {
                    return (record["sourceApiCode"] === 'MANUAL' ? <EditButton/> : "")
                }}/>
                <FunctionField render={(record) => {
                    return (record["sourceApiCode"] === 'MANUAL' ? <DeleteWithConfirmButton/> : "")
                }}/>
            </CheckPermission>
        </Datagrid>
    </List>
);

export const HistorySpotList = (props) => (
    <List {...props} actions={<TopToolbar/>} sort={{field: "id", order: "DESC"}} hasCreate={false} perPage={25}>
        <Datagrid rowClick={false} bulkActionButtons={false} contentEditable={false}>
            <TextField source="currencyFrom"/>
            <TextField source="currencyTo"/>
            <NumberField source="amount"/>
            <DateField source="receivedDate" label="Received Date (Local)" showTime/>
            <DateTimeFieldLondon source="receivedDate" label={"Received Date (London)"}/>

            <ReferenceField label="Ext API" source="sourceApiCode" reference={Resources.EXT_API.name}>
                <TextField source="name"/>
            </ReferenceField>
        </Datagrid>
    </List>
);
