import { useState, useCallback } from 'react';

export const useModal = (Component, options) => {
  const [{ isOpen, ...state }, setState] = useState({ isOpen: false });
  const { onClose, ...rest } = options || {};
  const props = { ...rest, ...state };

  const showDialog = useCallback(
    (data) => setState({ isOpen: true, ...data }),
    [],
  );

  const closeDialog = useCallback(
    (...args) => {
      setState({ isOpen: false });
      if (onClose) {
        onClose(...args);
      }
    },
    [onClose],
  );

  const DialogComponent = isOpen ? (
    <Component isOpen={isOpen} onClose={closeDialog} {...props} />
  ) : null;

  return [DialogComponent, showDialog, isOpen];
};
