import {ReferenceField, useFieldValue, FunctionField} from "react-admin";
import * as React from "react";
import PropTypes from "prop-types";

const EnumDictField = (props) => {
    const value = useFieldValue({source: props.source});
    return (
        <>
            <FunctionField label={props.label} render={(record) => props.enum?.[value]?.label} />
        </>
    );
}

EnumDictField.propTypes = {
    enum: PropTypes.object.isRequired,
    label: PropTypes.string,
    source: PropTypes.string.isRequired,
}

export default EnumDictField;
