import PropTypes from 'prop-types';
import {Button, Confirm, useDataProvider, useNotify, useRefresh} from 'react-admin';
// import dataProvider from '../../data/MvtDataProvider'
import {useState} from 'react';

export const ActionButton = (props) => {

    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const dataProvider = useDataProvider();

    const refresh = useRefresh();
    const notify = useNotify();

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = () => {
        const {record, action, method, resource} = props;
        const query = {
            resource,
            resourceId: record.id,
            action,
        }
        setIsLoading(true);

        dataProvider['doAction'](resource, {
            action,
            id: record.id,
            method,
        }).then(({ data }) => {
            setOpen(false);
            setIsLoading(false);
            // console.log(data)
            if (data.success) {
                notify('Status changed', {type: 'success', autoHideDuration: 2000});
                refresh();
            } else {
                const msg =
                    data.errMessage
                        ? data.errMessage
                        : "System error";
                notify(`The action ${action} failed with error: ${msg}`, {type: 'warning'});
            }
        }).catch(error => {
            setOpen(false);
            setIsLoading(false);
            console.error(resource + ' was not ' + action + "'ed", error)
            const msg = error.response?.data?.errMessage ? error.response?.data?.errMessage : error.message;
            notify(`The action '${action}' failed for resource ${resource} with error: ${msg}`, {type: 'warning'});
        });

        /*
                dataProvider('doAction', resource,
                    {id: record.id, method: method, action: action})
                    .then(() => {
                        showNotification('Done.');
                        push('/' + resource);
                    })
                    .catch((e) => {
                        showNotification('Error: ' + resource + ' was not approved', 'warning');
                        console.error(resource + ' was not approved', e)
                    });
        */
    }

    return (
        <>
            <Button label={props.label} color={props.color} onClick={handleClick} disabled={isLoading}/>
            { !props.withoutConfirmation  &&
                <Confirm
                    isOpen={open}
                    loading={isLoading}
                    title="Confirm"
                    content={`Are you sure you want to ${props.action} the item?`}
                    onConfirm={handleConfirm}
                    onClose={handleDialogClose}
                />
            }
        </>
    );
}

ActionButton.propTypes = {
    record: PropTypes.object,
    showNotification: PropTypes.func,
    action: PropTypes.string,
    method: PropTypes.string,
    resource: PropTypes.string,
    withoutConfirmation: PropTypes.bool,
};

export default ActionButton;
