import PropTypes from 'prop-types'
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {Button, FileField, FileInput, Form, TextInput, useDataProvider, useNotify, useRefresh} from "react-admin";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import React, {useState} from "react";
import {Grid} from "@mui/material";
import Resources from "../../Resources";
import {onError} from "../../common/utils";
import LoanApplicationTitle from "../controls/LoanApplicationTitle";

const ReplaceBriefDescriptionDialog = (props) => {
    const {close, loanApplication, open} = props;
    const loanApplicationId = loanApplication.id;
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();

    const [isLoading, setIsLoading] = useState(false);

    const handleClose = (event, reason) => {
        // console.log("handleClose", event, reason)
        if (reason && (reason === "backdropClick" || reason === "escapeKeyDown"))
            return;

        close();
    }

    const onSuccessDocumentUpload = (doc) => {
        notify(`One pager document ${doc.filename} successfully uploaded`, {type: 'success', autoHideDuration: 2000});
        refresh();
        close();
    }

    const doUpload = (data) => {
        setIsLoading(true);
        const formData = new FormData();
        const e = data.documentFile
        let path = e.rawFile.webkitRelativePath;
        if (!path) {
            path = e.rawFile.path.startsWith("/") ? e.rawFile.path.substring(1) : e.rawFile.path;
        }
        formData.append("documentFile.rawFile", e.rawFile, path);

        if (data.description) {
            formData.append("description", data.description);
        }

        dataProvider['doUpload'](Resources.LOAN_APPLICATIONS.name, {
            action: Resources.LOAN_APPLICATIONS.actions.ONE_PAGER.name,
            id: loanApplicationId,
            method: 'PUT',
            body: formData
        }).then(({data}) => {
            setIsLoading(false)
            if (data.success) {
                onSuccessDocumentUpload(data);
            } else {
                const msg =
                    data.errMessage
                        ? data.errMessage
                        : "System error";
                onError({message: msg}, notify)
            }
        }).catch(error => {
            setIsLoading(false)
            onError(error, notify)
        });
    }

    const validate = (form) => {
        const errors = {};
        if (!form.documentFile) {
            errors.documentFile = "Brief description document is required";
        }
        return errors;
    }

    return (
        <Dialog onClose={handleClose} open={open} maxWidth="md" disableEscapeKeyDown >
            <DialogTitle>Add or replace One pager document (<LoanApplicationTitle  loanApplication={loanApplication} />)</DialogTitle>
            <Form onSubmit={doUpload} validate={validate}>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={11}>
                            Upload a new One pager document. The old one will be deleted.
                        </Grid>
                        <Grid item xs={11}>
                            <FileInput source="documentFile" fullWidth multiple={false} accept={{ 'application/pdf': ['.pdf'] }}
                                       options={{noDrag: true, noDragEventsBubbling: true, preventDropOnDocument: true}}>
                                <FileField source="src" title="rawFile.path"/>
                            </FileInput>
                        </Grid>
                        <Grid item xs={11}>
                            <TextInput multiline source="description" title="Description" fullWidth/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} label="Cancel"/>
                    <Button type="submit" disabled={isLoading} label="Add"/>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

export default ReplaceBriefDescriptionDialog;

ReplaceBriefDescriptionDialog.propTypes = {
  close: PropTypes.func.isRequired,
  loanApplication: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
}